import { useContext, useEffect } from 'react'

import { useAuth } from 'lib/hooks/useAuth'
import { useApi } from 'lib/hooks/useApi'
import { Partner, UserConfig } from 'types'
import { GlobalContext } from 'store/global'

// We only want to call initial-data once.
let isFirstLoad = true

export const useFetchCharity = (): void => {
  const [
    { availablePartners, masqueradePartnerId },
    { setInitialPartner, setMasqueradePartner },
  ] = useContext(GlobalContext)
  const { isAuthenticated } = useAuth()

  const { data } = useApi<UserConfig>('partners/initial-data', {
    enabled: isAuthenticated && isFirstLoad,
  })

  const { data: masqueradePartnerData } = useApi<Partner>(
    `partners/${masqueradePartnerId}`,
    {
      enabled: isAuthenticated && !!masqueradePartnerId,
    }
  )

  useEffect(() => {
    if (data) {
      isFirstLoad = false
      setInitialPartner(data)
    } else if (masqueradePartnerData) {
      // eslint-disable-next-line no-console
      console.debug(`Setting partner to ${masqueradePartnerData.id}`)
      setMasqueradePartner({
        activePartner: { ...masqueradePartnerData },
      })
      window.localStorage.setItem(
        'masqueradePartnerId',
        masqueradePartnerData.id
      )
    }
  }, [
    availablePartners,
    setInitialPartner,
    data,
    masqueradePartnerData,
    setMasqueradePartner,
  ])
}
