/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import styled from 'styled-components'

import { Button, H, P, Wrapper } from '@farewill/ui'
import { DefaultLayout } from 'layouts/DefaultLayout'

const StyledWrapper = styled(Wrapper)`
  width: 100%;
`

export const Resources = (): React.ReactElement => (
  <DefaultLayout>
    <StyledWrapper container>
      <Wrapper margin={[0, 0, 'L']}>
        <H size="L" tag="h1">
          Resources
        </H>
        <P>
          The following resources can be used to help you run your campaigns.
        </P>
        <H size="S" tag="h2">
          Partner hub
        </H>
        <P>
          Find inspiration for your free will campaigns, read FAQs on our
          service and discover what&apos;s new at Farewill.
        </P>
        <Button.Primary
          href="https://partnerships.farewill.com/handbook"
          target="_blank"
        >
          View hub
        </Button.Primary>
      </Wrapper>
      <Wrapper margin={[0, 0, 'L']}>
        <H size="S" tag="h2">
          Campaign material
        </H>
        <P>
          Share your campaign material with Account Management for feedback and
          advice, and ensure your free will service is best set up for success.
          We especially recommend sharing campaign material for feedback before
          launching your first campaign. Allow 5 working days for feedback.
        </P>
        <P>
          You will need a separate log-in to access the Partner Hub. Get in
          touch at{' '}
          <a href="mailto:charities@farewill.com">charities@farewill.com</a> if
          anyone in your organisation needs access.
        </P>
        <Button.Primary
          href="https://share.hsforms.com/1g9QC7B5KQhy0iFIYJX14OQ323vv"
          target="_blank"
        >
          Submit campaign material
        </Button.Primary>
      </Wrapper>
      <Wrapper margin={[0, 0, 'L']}>
        <H size="S" tag="h2">
          Voucher codes
        </H>

        <P>
          Request a new voucher code for your next campaign by filling in the
          form below. Once the form has been submitted, within 5 working days,
          Account Management will set up the associated co-branded landing page
          you can promote in your campaign material.
        </P>
        <P>
          You can then direct your supporters to this page to get started on
          their free will.
        </P>

        <Button.Primary
          href="https://share.hsforms.com/1P9S7icXYTJa3e3JcHkslJQ323vv"
          target="_blank"
        >
          Request voucher code
        </Button.Primary>
      </Wrapper>
    </StyledWrapper>
  </DefaultLayout>
)
