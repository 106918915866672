import React, { useContext } from 'react'
import BaseStylesheet, { FontStylesheet } from '@farewill/ui/base'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Provider as ToastProvider } from '@radix-ui/react-toast'

import { Routes as PathNames } from 'lib/constants'
import { PrivateRoute } from 'components/PrivateRoute'
import { useFetchCharity } from 'lib/hooks/useFetchCharity'
import { Login } from 'routes/Login'
import { Overview } from 'routes/Overview'
import { Online } from 'routes/Online'
import { Resources } from 'routes/Resources'
import { Telephone } from 'routes/Telephone'
import { Assistant } from 'routes/Assistant'
import { withFilterProvider } from 'store/filter'
import { GlobalProvider, GlobalContext } from 'store/global'

const AppWithoutProviders = (): React.ReactElement => {
  const [{ partner }] = useContext(GlobalContext)

  useFetchCharity()

  return (
    <>
      <FontStylesheet assetFolder="/assets/" />
      <BaseStylesheet />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/assistant/*"
          element={
            <PrivateRoute>
              <Assistant />
            </PrivateRoute>
          }
        />

        {partner?.offers_telephone_wills ? (
          <>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Overview />
                </PrivateRoute>
              }
            />
            <Route
              path={PathNames.Online}
              element={
                <PrivateRoute>
                  <Online />
                </PrivateRoute>
              }
            />
            <Route
              path={PathNames.Telephone}
              element={
                <PrivateRoute>
                  <Telephone />
                </PrivateRoute>
              }
            />
          </>
        ) : (
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Online />
              </PrivateRoute>
            }
          />
        )}

        <Route
          path={PathNames.Resources}
          element={
            <PrivateRoute>
              <Resources />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute>
              <Navigate to="/" />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  )
}

const AppWithFilterProvider = withFilterProvider(AppWithoutProviders)

export const App = (): React.ReactElement => (
  <GlobalProvider>
    <ToastProvider>
      <AppWithFilterProvider />
    </ToastProvider>
  </GlobalProvider>
)
