import { UserConfig } from 'types'

export enum ActionType {
  SetInitialPartner = 'SET_INITIAL_PARTNER',
  SetMasqueradePartnerId = 'SET_MASQUERADE_PARTNER_ID',
  SetMasqueradePartner = 'SET_MASQUERADE_PARTNER',
}

export interface State {
  canDownloadPartnerDataSharingReports: boolean
  partner: UserConfig['activePartner'] | null
  availablePartners: UserConfig['partners'] | null
  masqueradePartnerId?: string
}

export const initialState: State = {
  canDownloadPartnerDataSharingReports: false,
  partner: null,
  availablePartners: [],
  masqueradePartnerId: window.localStorage.getItem('masqueradePartnerId'),
}
