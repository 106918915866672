import React, { ReactNode, useContext } from 'react'
import styled from 'styled-components'

import { FONT, GTR } from '@farewill/ui/tokens'
import { GlobalContext } from 'store/global'

const StyledSpan = styled.span`
  margin-left: ${GTR.S};
  font-size: ${FONT.SIZE.L};
  font-weight: ${FONT.WEIGHT.MEDIUM};
`

const StyledTitle = styled.h1`
  display: inline-block;
  flex: 1 0 0;
  margin: 0 ${GTR.S};
  font-size: ${FONT.SIZE.L};
  font-weight: ${FONT.WEIGHT.MEDIUM};
`

export const PartnerName = ({ children }: { children?: ReactNode }) => {
  const [{ partner }] = useContext(GlobalContext)

  return (
    partner && (
      <>
        <StyledSpan>|</StyledSpan>
        <StyledTitle>
          {partner.name} {children}
        </StyledTitle>
      </>
    )
  )
}
