import React, { lazy, useContext, Suspense } from 'react'
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useMatch,
} from 'react-router-dom'
import styled from 'styled-components'

import { Wrapper } from '@farewill/ui'

import { Routes as Paths } from 'lib/constants'
import { GlobalContext } from 'store/global'
import { Spinner } from 'components/loader/Spinner'
import { RETURN_TO_PARM_NAME } from './constants'

const GenerateEmailsRoute = lazy(() => import('./Emails'))
const GenerateTerms = lazy(() => import('./Terms'))

const StyledWrapper = styled(Wrapper)`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
`

export const Assistant = (): React.ReactElement => {
  const [{ partner }] = useContext(GlobalContext)
  const location = useLocation()
  const match = useMatch('/*')

  if (!partner) {
    return (
      <StyledWrapper>
        <Spinner />
      </StyledWrapper>
    )
  }

  const consented = !!partner?.terms_and_conditions_consents?.find(
    (consent) =>
      consent.name === 'AI_MARKETING_BOT' && consent.consentType === 'ACCEPTED'
  )

  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  const isTermsPage = match.pathname === Paths.GenerateTerms

  if (consented && isTermsPage) {
    const returnTo = decodeURIComponent(
      new URLSearchParams(location.search).get(RETURN_TO_PARM_NAME) ||
        Paths.GenerateEmails
    )

    return <Navigate to={returnTo} />
  }

  if (!consented && !isTermsPage) {
    return (
      <Navigate
        to={`${Paths.GenerateTerms}?${RETURN_TO_PARM_NAME}=${encodeURIComponent(location.pathname)}`}
      />
    )
  }

  return (
    <>
      <Suspense
        fallback={
          <StyledWrapper>
            <Spinner />
          </StyledWrapper>
        }
      >
        <Routes>
          <Route path="/" element={<Navigate to={Paths.GenerateEmails} />} />
          <Route path="/email-campaigns" element={<GenerateEmailsRoute />} />
          <Route path="/terms" element={<GenerateTerms />} />
        </Routes>
      </Suspense>
    </>
  )
}
