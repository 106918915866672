import React from 'react'
import styled, { keyframes } from 'styled-components'
import { CrossIcon, Wrapper } from '@farewill/ui'
import { BORDER, COLOR, GTR } from '@farewill/ui/tokens'
import { paragraphM } from '@farewill/ui/helpers/text'
import * as Dialog from '@radix-ui/react-dialog'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'

const MODAL_OVERLAY_Z_INDEX = 100
const MODAL_CONTENT_Z_INDEX = MODAL_OVERLAY_Z_INDEX + 1

const overlayShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const overlayHide = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const contentShow = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`

const contentHide = keyframes`
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
`

const StyledCloseButton = styled.button`
  line-height: 0; // Ensure button is exactly the same size as the icon
`

const StyledDialogContent = styled(Dialog.Content)`
  --dialog-max-height: 95vh;
  --dialog-max-width: 512px;
  --dialog-padding: ${GTR.L};
  animation-duration: 350ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  background-color: ${COLOR.WHITE};
  border-radius: ${BORDER.RADIUS.M};
  box-shadow: ${BORDER.SHADOW.M};
  display: flex;
  flex-direction: column;
  left: 50%;
  max-height: var(--dialog-max-height);
  max-width: var(--dialog-max-width);
  overflow: hidden;
  padding: 0;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  z-index: ${MODAL_CONTENT_Z_INDEX};

  &[data-state='closed'] {
    animation-name: ${contentHide};
  }

  &[data-state='open'] {
    animation-name: ${contentShow};
  }
`

const StyledDialogContentWrapper = styled(Wrapper)`
  overflow: auto;
  padding: var(--dialog-padding);
`

const StyledDialogTitle = styled(Dialog.Title)`
  ${paragraphM}
  margin: 0;
`

const StyledDialogHeader = styled(Wrapper)`
  align-items: flex-start;
  border-bottom: 1px solid ${COLOR.GREY.LIGHT};
  display: flex;
  justify-content: space-between;
`

const StyledOverlay = styled(Dialog.Overlay)`
  animation-duration: 350ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  inset: 0;
  z-index: ${MODAL_OVERLAY_Z_INDEX};

  &[data-state='closed'] {
    animation-name: ${overlayHide};
  }

  &[data-state='open'] {
    animation-name: ${overlayShow};
  }
`

type ModalProps = {
  children: React.ReactNode
  onOpenChange?: (open: boolean) => void
  open?: boolean
}

export const Modal = ({ children, onOpenChange, open }: ModalProps) => {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      {children}
    </Dialog.Root>
  )
}

type ModalContentProps = Dialog.DialogContentProps & {
  children: React.ReactNode
  /**
   * Prevent the modal from being dismissed by clicking the backdrop or pressing
   * `ESC`. The only way the modal can be dismissed is by the modal content
   * setting the modal state. As a result, this should only be used in a
   * controlled modal (otherwise the user will never be able to close it…).
   */
  preventDismiss?: boolean
  /**
   * The ARIA role to use for the dialog. Defaults to `'dialog'`. If your modal
   * "interrupts a user's workflow to communicate an important message and
   * requires a response" then you should set this to `'alertdialog'`.
   * See https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/alertdialog_role
   */
  role?: 'dialog' | 'alertdialog'
  /**
   * If false the dialog will not contain a header. Defaults to `true`.
   */
  showHeader?: boolean
  /**
   * The to render in the dialog header. If showHeader is false this title will
   * still be rendered so screen reader users can understand the context of the
   * dialog.
   */
  title: string
}

const ModalContent = ({
  children,
  preventDismiss = false,
  role = 'dialog',
  showHeader = true,
  title,
  ...props
}: ModalContentProps) => {
  return (
    <Dialog.Portal>
      <StyledOverlay data-testid="modal-backdrop" />
      <StyledDialogContent
        role={role}
        onInteractOutside={(e) => {
          if (preventDismiss) {
            e.preventDefault()
          }
        }}
        onEscapeKeyDown={(e) => {
          if (preventDismiss) {
            e.preventDefault()
          }
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {showHeader ? (
          <StyledDialogHeader padding={[GTR.M]}>
            <StyledDialogTitle>{title}</StyledDialogTitle>
            <Dialog.Close asChild>
              <StyledCloseButton type="button" aria-label="Close">
                <CrossIcon />
              </StyledCloseButton>
            </Dialog.Close>
          </StyledDialogHeader>
        ) : (
          <VisuallyHidden asChild>
            <Dialog.Title>{title}</Dialog.Title>
          </VisuallyHidden>
        )}
        <StyledDialogContentWrapper padding={[GTR.L]}>
          {children}
        </StyledDialogContentWrapper>
      </StyledDialogContent>
    </Dialog.Portal>
  )
}

Modal.Close = Dialog.Close
Modal.Trigger = Dialog.Trigger
Modal.Content = ModalContent
