import React, { useLayoutEffect, useReducer } from 'react'
import { type AxiosRequestConfig } from 'axios'
import { client } from 'lib/axios/apiClient'

enum ActionType {
  ClearRequest = 'CLEAR_REQUEST',
  InitiateRequest = 'INITIATE_REQUEST',
  RequestCompleted = 'REQUEST_COMPLETE',
  RequestError = 'REQUEST_ERROR',
}

interface ClearRequestAction {
  type: ActionType.ClearRequest
}
interface InitiateRequestAction {
  type: ActionType.InitiateRequest
}
interface RequestCompletedAction<T> {
  type: ActionType.RequestCompleted
  payload: {
    data: T
  }
}
interface RequestErrorAction {
  type: ActionType.RequestError
  payload: {
    error: Error
  }
}
type Action<T> =
  | ClearRequestAction
  | InitiateRequestAction
  | RequestCompletedAction<T>
  | RequestErrorAction

export interface State<T> {
  loading: boolean
  error: null | Error
  data: null | T
}

const initialState = {
  loading: true,
  error: null,
  data: null,
}

const reducer = <T>(state: State<T>, action: Action<T>) => {
  switch (action.type) {
    case ActionType.ClearRequest:
      return { ...initialState, loading: false }
    case ActionType.InitiateRequest:
      return { ...state, loading: true, error: null }
    case ActionType.RequestCompleted:
      return { ...state, loading: false, data: action.payload.data }
    case ActionType.RequestError:
      return { ...state, loading: false, error: action.payload.error }
    default:
      return state
  }
}

type UseApiOptions = {
  enabled?: boolean
}

/**
 * Used to make calls to the Spotlight routes on the Backstage server.
 *
 * @param route - The route to call
 * @typeParam T - The type of the expected return value of the route
 * @returns A State object with loading, error and data properties
 */
export const useApi = <T, R = unknown>(
  route: string | null,
  { enabled = true }: UseApiOptions = {},
  { method, transformResponse }: AxiosRequestConfig<R> = { method: 'GET' }
): State<T> => {
  const [state, dispatch] = useReducer<React.Reducer<State<T>, Action<T>>>(
    reducer,
    initialState
  )

  useLayoutEffect((): void => {
    const performRequest = async () => {
      if (route === null || !enabled)
        return dispatch({ type: ActionType.ClearRequest })

      dispatch({ type: ActionType.InitiateRequest })

      const res = await client.request<T>({
        url: `/${route}`,
        method,
        transformResponse,
      })

      return dispatch({
        type: ActionType.RequestCompleted,
        payload: { data: res.data },
      })
    }

    performRequest().catch((error: Error) => {
      console.error('Error processing response:', error)
      dispatch({
        type: ActionType.RequestError,
        payload: { error },
      })
    })
  }, [enabled, route, method, transformResponse])

  return state
}
