import React, { useCallback, createContext, useReducer } from 'react'

import { State, initialState, ActionType } from './constants'
import * as reducers from './reducers'
import {
  setInitialPartner,
  setMasqueradePartner,
  setMasqueradePartnerId,
} from './ducks/partner'

/* eslint-disable @typescript-eslint/no-explicit-any */

interface Action {
  type: ActionType
  payload: any
}

const combinedReducers: React.Reducer<State, Action> = (state, action) =>
  Object.values(reducers).reduce(
    (prevState, reducer: React.Reducer<State, any>) =>
      reducer(prevState, action),
    state
  )

type Context = [State, Record<string, (payload?: any) => void>]
export const GlobalContext = createContext<Context>([
  {
    ...initialState,
  },
  {},
])

export const GlobalProvider = ({
  children,
}: {
  children: React.ReactNode
}): React.ReactElement => {
  const [state, dispatch] = useReducer(combinedReducers, initialState)

  /* eslint-disable react-hooks/exhaustive-deps */
  const actions = {
    setInitialPartner: useCallback(setInitialPartner(dispatch), [dispatch]),
    setMasqueradePartnerId: useCallback(setMasqueradePartnerId(dispatch), [
      dispatch,
    ]),
    setMasqueradePartner: useCallback(setMasqueradePartner(dispatch), [
      dispatch,
    ]),
  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <GlobalContext.Provider value={[state, actions]}>
      {children}
    </GlobalContext.Provider>
  )
}
